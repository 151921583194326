import { lazy } from "react";

export const ImageElement = lazy(() => import("./ImageElement"));
export const Breadcrumb = lazy(() => import("./Breadcrumb"));
export const Charts = lazy(() => import("./Charts"));
export const BootstrapCol = lazy(() => import("./BootstrapCol"));
export const DashboardCard = lazy(() => import("./DashboardCard"));
export const SweetAlert = lazy(() => import("./SweetAlert"));
export const Tabs = lazy(() => import("./Tabs"));
export const CustomInput = lazy(() => import("./Input"));
export const CustomSelect = lazy(() => import("./Select"));
export const CommonButton = lazy(() => import("./CommonButton"));
export const DataTable = lazy(() => import("./DataTable"));
export const CustomDatePicker = lazy(() => import("./DatePicker"));
export const CustomTextEditor = lazy(() => import("./TextEditor"));
export const CustomTextArea = lazy(() => import("./TextArea"));
export const SwiperSliderComponent = lazy(() => import("./SwiperSlider"));
export const TripCard = lazy(() => import("./TripCard"));
export const Switch = lazy(() => import("./Switch"));
export const AccordionComponent = lazy(() => import("./Accordion"));
export const PageLoader = lazy(() => import("./PageLoader"));

// export { default as ImageElement } from "./ImageElement";
// export { default as Breadcrumb } from './Breadcrumb';
// export { default as Charts } from './Charts';
