import {
  HomePage,
  UpcomingTrip,
  Search,
  Filter,
  TripDetails,
  TourGuide,
  Review,
  DaysPlan,
  Policies,
  Terms,
  Notification,
  Checkout,
  UsersProfile,
  UsersProfileEdit,
  ChangePassword,
  BookingHistory,
  BookingHistoryDetails,
  BookingPastDetails,
  AddReview,
  HelpSupport,
  UserFaq,
  UserAbout,
  UserCMS,
} from "../../pages";
import userRoutesMap from "../../routeControl/userRoutes";

export default function route() {
  return [
    {
      path: userRoutesMap.HOME.path,
      name: "Home",
      key: userRoutesMap.HOME.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <HomePage />,
    },
    {
      path: `${userRoutesMap.UPTRIP.path}/:type`,
      name: "Upcoming Trip",
      key: `${userRoutesMap.UPTRIP.path}/:type`,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UpcomingTrip />,
    },
    {
      path: userRoutesMap.SEARCH.path,
      name: "Search",
      key: userRoutesMap.SEARCH.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Search />,
    },
    {
      path: userRoutesMap.FILTER.path,
      name: "Filter",
      key: userRoutesMap.FILTER.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Filter />,
    },
    {
      path: `${userRoutesMap.DETAILS.path}/:id`,
      name: "Trip Details",
      key: `${userRoutesMap.DETAILS.path}/:id`,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <TripDetails />,
    },
    {
      path: `${userRoutesMap.GUIDE.path}/:id`,
      name: "Tour Guide",
      key: `${userRoutesMap.GUIDE.path}/:id`,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <TourGuide />,
    },
    {
      path: userRoutesMap.REVIEW.path,
      name: "Review",
      key: userRoutesMap.REVIEW.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Review />,
    },
    {
      path: userRoutesMap.DAYS.path,
      name: "Days Plan",
      key: userRoutesMap.DAYS.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <DaysPlan />,
    },
    {
      path: userRoutesMap.POLICIES.path,
      name: "Policies",
      key: userRoutesMap.POLICIES.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserCMS />,
    },
    {
      path: userRoutesMap.TERMS.path,
      name: "Terms",
      key: userRoutesMap.TERMS.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserCMS />,
    },
    {
      path: userRoutesMap.NOTIFICATION.path,
      name: "Notification",
      key: userRoutesMap.NOTIFICATION.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Notification />,
    },
    {
      path: userRoutesMap.CHECKOUT.path,
      name: "checkout",
      key: userRoutesMap.CHECKOUT.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <Checkout />,
    },
    {
      path: userRoutesMap.PROFILES.path,
      name: "My Profile",
      key: userRoutesMap.PROFILES.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UsersProfile />,
    },
    {
      path: userRoutesMap.EDITPROFILES.path,
      name: "Edit Profile",
      key: userRoutesMap.EDITPROFILES.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UsersProfileEdit />,
    },
    {
      path: userRoutesMap.CHNAGEPWD.path,
      name: "Change Password",
      key: userRoutesMap.CHNAGEPWD.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <ChangePassword />,
    },
    {
      path: userRoutesMap.BOOKING.path,
      name: "Booking History",
      key: userRoutesMap.BOOKING.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <BookingHistory />,
    },
    {
      path: `${userRoutesMap.BOOKINGHISTORY.path}/:id`,
      name: "Booking History Details",
      key: `${userRoutesMap.BOOKINGHISTORY.path}/:id`,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <BookingHistoryDetails />,
    },
    {
      path: userRoutesMap.BOOKINGPAST.path,
      name: "Booking Past Details",
      key: userRoutesMap.BOOKINGPAST.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <BookingPastDetails />,
    },
    {
      path: userRoutesMap.ADDREVIEW.path,
      name: "Add Review",
      key: userRoutesMap.ADDREVIEW.path,
      private: true,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <AddReview />,
    },
    {
      path: userRoutesMap.HELPS.path,
      name: "Help Support",
      key: userRoutesMap.HELPS.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <HelpSupport />,
    },
    {
      path: userRoutesMap.USERFAQS.path,
      name: "User Faqs",
      key: userRoutesMap.USERFAQS.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserFaq />,
    },
    {
      path: userRoutesMap.USERABOUT.path,
      name: "About Us",
      key: userRoutesMap.USERABOUT.path,
      private: false,
      common: true,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <UserAbout />,
    },
  ];
}
