import { lazy } from "react";

export const AdminLoginForm = lazy(() => import("./Login"));
export const AdminSignUpForm = lazy(() => import("./SignUp"));
export const AdminForgotPasswordForm = lazy(() =>
  import("./ForgotPasswordForm")
);
export const AdminResetPasswordForm = lazy(() => import("./ResetPassword"));
export const AdminVerificationForm = lazy(() => import("./VerifyOTP"));
export const AdminVendorProfileStepOneForm = lazy(() =>
  import("./VendorProfileStepOne")
);
export const AdminVendorProfileStepTwoForm = lazy(() =>
  import("./VendorProfileStepTwo")
);
export const AdminVendorProfileStepThreeForm = lazy(() =>
  import("./VendorProfileStepThree")
);
export const AdminAddTripStepOneForm = lazy(() => import("./AddTripStepOne"));
export const AdminAddTripStepTwoForm = lazy(() => import("./AddTripStepTwo"));
export const AdminAddTripStepThreeForm = lazy(() =>
  import("./AddTripStepThree")
);
export const AdminChangePasswordForm = lazy(() =>
  import("./ChangePassword/index.form")
);
