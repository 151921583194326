import {
  BookingDetails,
  BookingTripList,
  // BookingViewDetails,
} from "../../../pages";
import adminRoutesMap from "../../../routeControl/adminRoutes";

export default function route() {
  return [
    {
      path: adminRoutesMap.BOOKINGTRIPLIST.path,
      name: "Booking Management",
      key: adminRoutesMap.BOOKINGTRIPLIST.path,
      private: true,
      belongsToSidebar: true,
      icon: "tripon-booking",
      element: <BookingTripList />,
    },
    {
      path: `${adminRoutesMap.BOOKINGTRIPDETAILS.path}/:id`,
      name: "Booking Details",
      key: `${adminRoutesMap.BOOKINGTRIPDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      element: <BookingDetails />,
    },
  ];
}
