import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ImageElement } from "../UiElement";
import { getSideBarMenu } from "../../utils/common.util";
import adminRoutesMap from "../../routeControl/adminRoutes";

function AdminSidebar({ setActive, routeList }) {
  const location = useLocation();
  let menu = useMemo(() => {
    let data = getSideBarMenu(routeList);
    return data;
  }, [routeList]);

  const ToggleOverlay = () => {
    document.querySelector("body").classList.remove("openSidebar");
    setActive(false);
    document
      .querySelector(".adminHeader_click em")
      .classList.remove("tripon-close");
    document
      .querySelector(".adminHeader_click em")
      .classList.add("tripon-menu");
  };

  const getActiveMenu = (pathname) => {
    let path = location?.pathname;
    path =
      location?.pathname.includes("add-trip") ||
      location?.pathname.includes("edit-trip") ||
      location?.pathname.includes("trip-details")
        ? adminRoutesMap.ADDTRIPLIST.path
        : path;
    path = location?.pathname.includes("booking-details")
      ? adminRoutesMap.BOOKINGTRIPLIST.path
      : path;
    return path === pathname;
  };
  return (
    <>
      <aside className="sideBar">
        <div className="sideBar_logo">
          <Link to="/admin/dashboard" className="d-block text-center">
            <ImageElement source="logo-sidebar.svg" className="img-fluid" />
          </Link>
        </div>
        <div className="sideBar_list">
          {menu?.map((item, key) => {
            return (
              <div
                className={`sideBar_list_items ${
                  getActiveMenu(item?.path) ? "active" : ""
                }`}
                key={key}
              >
                <Link to={item?.path} className="sideBar_list_menu">
                  <em className={item?.icon} /> {item?.label}
                </Link>
              </div>
            );
          })}
          {/* <div className="sideBar_list_items active">
            <Link to="/admin/dashboard" className="sideBar_list_menu"><em className="tripon-dashboard" /> Dashboard</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/add-trip-list" className="sideBar_list_menu"><em className="tripon-trips" />Add Trip Management</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/booking-trip-list" className="sideBar_list_menu"><em className="tripon-booking" />Booking Management </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/payment-list" className="sideBar_list_menu"><em className="tripon-payment" />Payment </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/report-list" className="sideBar_list_menu"><em className="tripon-analytics" />Reports </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/review-list" className="sideBar_list_menu"><em className="tripon-star" />Review List</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/support" className="sideBar_list_menu"><em className="tripon-support" />Support </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/admin/cms" className="sideBar_list_menu"><em className="tripon-file" />CMS </Link>
          </div> */}
        </div>
      </aside>
      <div className="sideOverlay" onClick={() => ToggleOverlay()} />
    </>
  );
}

export default AdminSidebar;
